import React from "react";
import Intro from "../../../components/intro/index";
import HomeData from "../../../data/home.json";

const IntroContainer = () => {
    return (
        <div className="intro-slider-wrap">
            <Intro data={HomeData[0].intro} key={HomeData[0].intro.id} />
        </div>
    );
};

export default IntroContainer;
