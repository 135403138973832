import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const PortfolioDetailsContainer = ({ data, pager }) => {
    const cate = data.categories.map((value, idx) => {
        return (
            <span className="d-inline" key={idx}>
                {value}
                {idx !== data.categories.length - 1 && " , "}
            </span>
        );
    });
    const prev = pager.prev[0] ?? null;
    const next = pager.next[0] ?? null;

    return (
        <div className="portfolio-area portfolio-single">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner-content">
                            <div className="content" data-aos="fade-up">
                                <p className="category">{cate}</p>
                                <h3 className="title">{data.title}</h3>
                            </div>
                            <div className="portfolio-info">
                                <div className="row">
                                    <div
                                        className="col-sm-6 col-md-3 col-lg-3"
                                        data-aos="fade-up"
                                    >
                                        <div className="info-item">
                                            <span>Client</span>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: data.client,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-sm-6 col-md-3 col-lg-3"
                                        data-aos="fade-up"
                                        data-aos-delay="300"
                                    >
                                        <div className="info-item">
                                            <span>Date</span>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: data.date,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-sm-6 col-md-3 col-lg-3"
                                        data-aos="fade-up"
                                        data-aos-delay="600"
                                    >
                                        <div className="info-item">
                                            <span>Team</span>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: data.team,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-sm-6 col-md-3 col-lg-3"
                                        data-aos="fade-up"
                                        data-aos-delay="900"
                                    >
                                        <div className="info-item style-two">
                                            <span>Services</span>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: data.services,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="portfolio-content"
                                data-aos="fade-up"
                            >
                                {data.body.map((value, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="content-wrap"
                                            dangerouslySetInnerHTML={{
                                                __html: value,
                                            }}
                                        />
                                    );
                                })}
                            </div>
                            {/* TODO group into sets of 3, loop through sets. */}
                            <div className="row">
                                {data.gallery.imageOne && (
                                    <div
                                        className="col-lg-4"
                                        data-aos="fade-up"
                                        data-aos-delay="300"
                                    >
                                        <div
                                            className="thumb section-padding-bottom"
                                            data-aos="fade-up"
                                            data-aos-delay="300"
                                        >
                                            <img
                                                className="w-100"
                                                src={`${process.env.PUBLIC_URL}${data.gallery.imageOne.src}`}
                                                alt={data.gallery.imageOne.alt}
                                            />
                                        </div>
                                    </div>
                                )}

                                {data.gallery.imageTwo && (
                                    <div
                                        className="col-lg-4"
                                        data-aos="fade-up"
                                        data-aos-delay="300"
                                    >
                                        <div
                                            className="thumb section-padding-bottom"
                                            data-aos="fade-up"
                                            data-aos-delay="300"
                                        >
                                            <img
                                                className="w-100"
                                                src={`${process.env.PUBLIC_URL}${data.gallery.imageTwo.src}`}
                                                alt={data.gallery.imageTwo.alt}
                                            />
                                        </div>
                                    </div>
                                )}
                                {data.gallery.imageThree && (
                                    <div
                                        className="col-lg-4"
                                        data-aos="fade-up"
                                        data-aos-delay="300"
                                    >
                                        <div
                                            className="thumb section-padding-bottom"
                                            data-aos="fade-up"
                                            data-aos-delay="300"
                                        >
                                            <img
                                                className="w-100"
                                                src={`${process.env.PUBLIC_URL}${data.gallery.imageThree.src}`}
                                                alt={
                                                    data.gallery.imageThree.alt
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="row">
                                {data.gallery.imageFour && (
                                    <div
                                        className="col-lg-4"
                                        data-aos="fade-up"
                                        data-aos-delay="300"
                                    >
                                        <div
                                            className="thumb section-padding-bottom"
                                            data-aos="fade-up"
                                            data-aos-delay="300"
                                        >
                                            <img
                                                className="w-100"
                                                src={`${process.env.PUBLIC_URL}${data.gallery.imageFour.src}`}
                                                alt={data.gallery.imageFour.alt}
                                            />
                                        </div>
                                    </div>
                                )}

                                {data.gallery.imageFive && (
                                    <div
                                        className="col-lg-4"
                                        data-aos="fade-up"
                                        data-aos-delay="300"
                                    >
                                        <div
                                            className="thumb section-padding-bottom"
                                            data-aos="fade-up"
                                            data-aos-delay="300"
                                        >
                                            <img
                                                className="w-100"
                                                src={`${process.env.PUBLIC_URL}${data.gallery.imageFive.src}`}
                                                alt={data.gallery.imageFive.alt}
                                            />
                                        </div>
                                    </div>
                                )}
                                {data.gallery.imageSix && (
                                    <div
                                        className="col-lg-4"
                                        data-aos="fade-up"
                                        data-aos-delay="300"
                                    >
                                        <div
                                            className="thumb section-padding-bottom"
                                            data-aos="fade-up"
                                            data-aos-delay="300"
                                        >
                                            <img
                                                className="w-100"
                                                src={`${process.env.PUBLIC_URL}${data.gallery.imageSix.src}`}
                                                alt={data.gallery.imageSix.alt}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <div className="social-icons">
                            <span>Share:</span>
                            {/* <a
                                href="https://facebook.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="icofont-facebook"></i>
                            </a> */}
                            <a
                                href="https://www.instagram.com/mireli_zavala"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="icofont-instagram"></i>
                            </a>
                        </div>
                    </div>
                    {/* <div
                        className="col-lg-12 text-center"
                        data-aos="fade-up"
                        data-aos-delay="300"
                    >
                        <Link
                            to={process.env.PUBLIC_URL + "/"}
                            className="btn-started"
                        >
                            Start your project with us now{" "}
                            <span>Get Started</span>{" "}
                            <i className="arrow_carrot-right_alt2"></i>
                        </Link>
                    </div> */}
                </div>
                <div className="row">
                    <div
                        className="col-lg-12"
                        data-aos="fade-up"
                        data-aos-delay="600"
                    >
                        <div className="portfolio-navigation">
                            {prev && (
                                <div className="prev">
                                    <Link
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/portfolio-details/" +
                                            prev.id
                                        }
                                    >
                                        <i className="arrow_carrot-left"></i>{" "}
                                        Previous
                                    </Link>
                                    {prev.title}
                                </div>
                            )}
                            {next && (
                                <div className="next">
                                    <Link
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/portfolio-details/" +
                                            next.id
                                        }
                                    >
                                        Next
                                        <i className="arrow_carrot-right"></i>{" "}
                                    </Link>
                                    {next.title}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

PortfolioDetailsContainer.propTypes = {
    data: PropTypes.object,
    pager: PropTypes.object,
};

export default PortfolioDetailsContainer;
