import PropTypes from "prop-types";

const Intro = ({ data }) => {
    return (
        <img
            src={process.env.PUBLIC_URL + data.backgroundImage}
            alt={data.desc}
            title={data.title}
        />
    );
};

Intro.propTypes = {
    data: PropTypes.object,
};

export default Intro;
